// Next.js's environment configuration is built into the bundles.
// That disallows run-time configuration, which is important for us.
// On the server, configuration can be taken directly from environment
// variables, on the client it is served by the /api/config endpoint,
// which sets window.__config__.
//
// Note that only variables prefixed with NEXT_PUBLIC_ are allowed,
// for security, but their prefix is stripped in this file for ease
// of use.
//
// Do _not_ put server-side variables here, but reference them
// directly using process.env. Think twice before using build-time
// variables (like isProdBuild and isDevBuild).

const isProdBuild = process.env.NODE_ENV === 'production';
const isDevBuild =
  process.env.NODE_ENV === 'development' || !process.env.NODE_ENV;

if (typeof window !== 'undefined') {
  // client-side
  const cfg = window.__config__ || {};
  module.exports = {
    isProdBuild,
    isProdRuntime: cfg.NEXT_PUBLIC_RUNTIME_ENV === 'production',
    isDevBuild,
    isDevRuntime:
      cfg.NEXT_PUBLIC_RUNTIME_ENV === 'development' ||
      !cfg.NEXT_PUBLIC_RUNTIME_ENV,
    BUGSNAG_API_KEY: cfg.NEXT_PUBLIC_BUGSNAG_API_KEY,
    RUNTIME_ENV: cfg.NEXT_PUBLIC_RUNTIME_ENV,
    GA_CONVERSION_ID: cfg.NEXT_PUBLIC_GA_CONVERSION_ID,
    GA_CONVERSION_LABEL: cfg.NEXT_PUBLIC_GA_CONVERSION_LABEL,
    FB_APP_ID: cfg.NEXT_PUBLIC_FB_APP_ID
  };
} else {
  // server-side
  module.exports = {
    isProdBuild,
    isProdRuntime: process.env.NEXT_PUBLIC_RUNTIME_ENV === 'production',
    isDevBuild,
    isDevRuntime:
      process.env.NEXT_PUBLIC_RUNTIME_ENV === 'development' ||
      !process.env.NEXT_PUBLIC_RUNTIME_ENV,
    BUGSNAG_API_KEY: process.env.NEXT_PUBLIC_BUGSNAG_API_KEY,
    RUNTIME_ENV: process.env.NEXT_PUBLIC_RUNTIME_ENV,
    GA_CONVERSION_ID: process.env.NEXT_PUBLIC_GA_CONVERSION_ID,
    GA_CONVERSION_LABEL: process.env.NEXT_PUBLIC_GA_CONVERSION_LABEL,
    FB_APP_ID: process.env.NEXT_PUBLIC_FB_APP_ID
  };
}
