import * as prismic from '@prismicio/client';
import * as prismicNext from '@prismicio/next';
import sm from './slicemachine.config.json';
import Bugsnag from '@bugsnag/js';

/**
 * The project's Prismic repository name.
 */
export const repositoryName = prismic.getRepositoryName(sm.apiEndpoint);

/**
 * The project's Prismic Link Resolver. This function determines the URL for a
 * given Prismic document.
 *
 * A Link Resolver is used rather than a Route Resolver because we need to
 * resolve URLs for documents' `alternate_languages` items. The
 * `alternate_languages` array does not include URLs.
 *
 * @type {prismicH.LinkResolverFunction}
 */
export const linkResolver = (doc) => {
  if (doc.type === 'page') {
    if (doc.uid === 'home') {
      return '/';
    } else {
      return doc.data?.listRedirect?.url || `/${doc.uid}`;
    }
  } else if (doc.type === 'programme') {
    return `/programme/${doc.uid}`;
  } else if (doc.type === 'project') {
    return `/project/${doc.uid}`;
  }

  Bugsnag.notify(
    new Error(`Could not resolve link of type ${doc.type}`),
    function (e) {
      e.severity = 'warning';
      e.addMetadata('doc', doc);
    }
  );

  return '/';
};

/**
 * Creates a Prismic client for the project's repository. The client is used to
 * query content from the Prismic API.
 *
 * @param config {prismicNext.CreateClientConfig} - A configuration object to
 */
export const createClient = ({ previewData, req, ...config } = {}) => {
  const client = prismic.createClient(sm.apiEndpoint, config);

  prismicNext.enableAutoPreviews({ client, previewData, req });

  return client;
};
