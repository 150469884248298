import React from 'react';
import Link from 'next/link';
import { PrismicLink, PrismicProvider } from '@prismicio/react';
import { PrismicPreview } from '@prismicio/next';
import { LocaleContext } from '../components/LocaleContext';

import { repositoryName, linkResolver } from '../prismicio';
import Bugsnag from '../lib/bugsnag';

import '../styles/globals.css';
import '../scss/main.scss';

const richTextComponents = {
  paragraph: ({ children }) => <p className="mb-3">{children}</p>,
  oList: ({ children }) => <ol className="mb-3 pl-4">{children}</ol>,
  oListItem: ({ children }) => <li className="mb-1 pl-1">{children}</li>,
  list: ({ children }) => <ul className="mb-3 pl-4">{children}</ul>,
  listItem: ({ children }) => <li className="mb-1 pl-1">{children}</li>,
  preformatted: ({ children }) => (
    <pre className="mb-5 rounded p-4 text-small">
      <code>{children}</code>
    </pre>
  ),
  strong: ({ children }) => <strong>{children}</strong>,
  hyperlink: ({ children, node }) => (
    <PrismicLink field={node.data} className="">
      {children}
    </PrismicLink>
  )
};

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

export default function App({ Component, pageProps }) {
  return (
    <ErrorBoundary>
      <PrismicProvider
        internalLinkComponent={Link}
        linkResolver={linkResolver}
        richTextComponents={richTextComponents}>
        <PrismicPreview repositoryName={repositoryName}>
          <LocaleContext.Provider value={pageProps.locale}>
            <Component {...pageProps} />
          </LocaleContext.Provider>
        </PrismicPreview>
      </PrismicProvider>
    </ErrorBoundary>
  );
}
