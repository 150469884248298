import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { BUGSNAG_API_KEY, RUNTIME_ENV } from './config';

Bugsnag.start({
  apiKey: BUGSNAG_API_KEY || 'undefined',
  enabledReleaseStages: BUGSNAG_API_KEY ? ['production', 'staging'] : [],
  releaseStage: RUNTIME_ENV,
  autoTrackSessions: false, // avoid unnecessary traffic
  collectUserIp: false, // GDPR
  generateAnonymousId: false, // we're not interested in user tracking
  plugins: [new BugsnagPluginReact()]
});

export default Bugsnag;
